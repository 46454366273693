.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  /* max-width: 350px; */
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  /* Centraliza o formulário horizontalmente */
  /* margin-left: 10rem; */
  margin-top: 0rem;
}

.pciImage {
  width: 50%;
  height: auto;
  margin-top: -20px;
  margin-bottom: 15px;
  /* Adiciona um espaçamento abaixo da imagem */
}


.registerLink {
  color: #e63888;
  /* Cor rosa */
  text-decoration: none;
}

.registerLink:hover {
  text-decoration: underline;
}

.form-container {
  min-height: 100vh;
  /* Ocupa no mínimo toda a altura da janela */
  display: flex;
  justify-content: center;
  /* Centraliza horizontalmente */
  align-items: center;
  /* Centraliza verticalmente */
  background-color: #e63888;
  /* Cor de fundo */
  padding: 2rem;
  /* Espaçamento para evitar que o formulário encoste nas bordas da página */
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: flex;
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px;
}

.input {
  width: 100%;
  max-width: 600px;
  /* height: 2rem; */
  /* padding: 10px; */
  border: 1px solid #e63888;
  border-radius: 10px;
  text-align: center;
  /* margin-left: 12em; */
  /* background-color: #e63888; /* Cor de fundo personalizada */
  /* color: #fff; Cor do texto (opcional, para contraste) */
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.button:hover {
  background-color: #c10045;
  color: #fff;
}

.agreementtext {
  font-size: 0.975rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}

/* Estilizando o container principal do dashboard dentro do form */
.dashboardContent {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

/* Sidebar fixa com links */
/* Adiciona ou atualiza o CSS para o menu */
.sidebar {
  display: flex;
  background: linear-gradient(135deg, rgba(226, 0, 85, 0.8) 0%, rgba(226, 0, 85, 0.6) 100%);
  /* Adiciona um gradiente diagonal com duas tonalidades da cor rosa */
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border-radius: 12px;
  /* Bordas um pouco mais arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Sombra mais suave e expandida */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Borda leve para definir a área */
}

/* .activeButton {
  background-color: #e63888;
  color: white;
  border: 1px solid #e63888;
}

.inactiveButton {
  background-color: white;
  color: #000;
  border: 1px solid #e63888;
} */

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: -3rem;
}

button {
  width: 100%;
  text-align: center;
}

.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 1rem;
  display: block;
  /* Garante que o submenu seja exibido como bloco */
}

.submenu li {
  margin: 0.5rem 0;
}

.submenu button {
  background: rgb(240, 240, 240);
  color: #000;
  border: 1px solid #e63888;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  height: auto;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}


/* Conteúdo principal do painel */
.mainContent {
  flex-grow: 1;
  margin-left: 20px;
}

.cardContainer {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Tamanho ajustado para caber dois cards por linha */
  margin-bottom: 20px;
}

.card h2 {
  font-size: 1.5rem;
  color: #e20055;
}

.card p {
  font-size: 1rem;
  color: #555;
}

/* Regras de mídia para garantir que o layout seja responsivo */
@media (max-width: 768px) {
  .dashboardContent {
    flex-direction: column;
  }

  .cardContainer {
    flex-direction: column;
  }

  .card {
    width: 100%;
    /* Cartões ocupam 100% da largura em telas menores */
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  /* Permite que os botões se movam para a próxima linha */
  gap: 10px;
  /* Espaçamento entre os botões */
  margin-top: 15px;
  /* Espaçamento acima dos botões */
  margin-left: 2rem;
}

button {
  flex: 0 1 calc(30% - 10px);
  /* Cada botão ocupa 50% da largura do container, menos o espaçamento */
  padding: 10px 20px;
  background-color: #e63888;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-sizing: border-box;
  /* Inclui padding e border na largura total do botão */
}

button:hover {
  background-color: #d12c77;
  color: #fff;
}

.submenu button.active {
  background-color: #e63888;
  color: #fff;
  border: 1px solid #e63888;
}

.submenu button.active:hover {
  background-color: #d12c77;
  color: #fff;
}

.sidebar h1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
  font-family: "Dancing Script", cursive;
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
  /* Necessário para posicionar o pseudo-elemento */
  overflow: hidden;
  /* Garante que a linha não ultrapasse os limites do texto */
}

.sidebar h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 18px;
  height: 3px;
  /* Espessura da linha */
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, #e63888 50%, rgb(255, 255, 255) 100%);
  background-size: 200% 100%;
  background-position: 0 0;
  animation: lineAnimation 2s infinite;
  /* Animação da linha */
}

@keyframes lineAnimation {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100vh; /* Faz com que ocupe toda a altura da tela */
  flex-direction: column; /* Alinha o spinner e o texto em coluna */
  text-align: center; /* Garante que o texto também seja centralizado */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Cor de fundo do spinner */
  border-top: 4px solid #e20055; /* Cor do topo do spinner */
  border-radius: 50%;
  width: 40px; /* Largura do spinner */
  height: 40px; /* Altura do spinner */
  animation: spin 1s linear infinite; /* Animação de rotação */
  margin-bottom: 10px; /* Espaço entre o spinner e o texto */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* Início da rotação */
  }
  to {
    transform: rotate(360deg); /* Fim da rotação */
  }
}