.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .closeButton {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center;     /* Centraliza verticalmente */
    width: 30px;             /* Ajuste o tamanho conforme necessário */
    height: 30px;            /* Ajuste o tamanho conforme necessário */
    color: #fff;
    font-size: 20px;         /* Ajuste o tamanho da fonte do símbolo &times; */
    border-radius: 17%;      /* Faz o botão ficar circular */
    border: none;            /* Remove a borda */
    background-color: #e63888;
    cursor: pointer;
}

  
  .closeButton:hover,
  .closeButton:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .modalContent h2 {
    text-align: center;
  }
  .modalContent span {
    text-align: center;
    color: #000;
  }
  .modalContent p {
    text-align: center;
  }

  .redirectButtonContainer {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    margin-top: 20px;
  }
  
  .redirectButton {
    background-color: #e63888; /* Altere conforme necessário */
    color: white;
    border: none;
    padding: 0px 87px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .redirectButton:hover {
    background-color: #b42b6b; /* Altere conforme necessário */
  }

  .input {
   display: flex;
   justify-content: center;
   margin-left: 5rem;
   text-align: center;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #e63888;
    border-radius: 5px;

  }
  
  .button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    /* margin-left: 7rem; */
    background-color: #e20055;
    color: #fff;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* margin-right: 10px; */
    font-size: 1rem;
  }
  
  .button:hover {
    background-color: #c10045;
  }