/* Estilos gerais para a aplicação */
.App {
  text-align: center;
  background: transparent; /* Ou remova esta linha se não for necessário */
  height: auto;
  width: 100%; /* Garante que a aplicação ocupe toda a largura disponível */
  margin: 0; /* Remove qualquer margem extra que possa estar adicionando espaço lateral */
  padding: 0; /* Remove o padding extra */
  box-sizing: border-box; /* Garante que o padding seja incluído no tamanho total */
}

/* Garantir que o body e html ocupem toda a tela e removam a rolagem lateral */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Remove rolagem lateral */
}

/* Cabeçalho da aplicação */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 40px;
  width: 100%; /* Garante que o cabeçalho ocupe toda a largura */
  box-sizing: border-box; /* Inclui o padding dentro da largura total */
}

/* Link de referência */
.App-link {
  color: #61dafb;
}

/* Estilos de responsividade para telas menores que 768px */
@media only screen and (max-width: 768px) {
  /* Ajustes no cabeçalho para telas pequenas */
  .App-header {
    padding: 20px; /* Reduz o padding para telas móveis */
    font-size: calc(8px + 2vmin); /* Ajuste para não perder legibilidade */
  }

  /* Reduzindo o tamanho do logo */
  .App-logo {
    height: 30vmin; /* Ajuste do tamanho da logo em dispositivos móveis */
  }

  /* Ajuste do texto do cabeçalho */
  .App-header h1 {
    font-size: 1.5rem; /* Ajuste de font-size para garantir melhor legibilidade */
  }

  /* Ajustes adicionais para garantir que o conteúdo se ajuste melhor */
  .App-link {
    font-size: 1.1rem; /* Ajuste do tamanho do link */
  }
}

/* Estilos para telas menores que 480px */
@media only screen and (max-width: 480px) {
  /* Reduzindo ainda mais o tamanho do logo para dispositivos muito pequenos */
  .App-logo {
    height: 25vmin;
  }

  /* Ajuste da fonte para tornar o conteúdo legível em dispositivos menores */
  .App-header {
    font-size: 1rem; /* Menor tamanho de fonte para telas bem pequenas */
  }

  .App-header h1 {
    font-size: 1.2rem;
  }

  /* Ajustes no link */
  .App-link {
    font-size: 1rem;
  }
}
