/* Estilos gerais */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

.navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e63888;
    padding: 1.0rem;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
  }

.logo {
    width: 95px;
    height: auto;
}

.logo img {
    max-width: 100%;
    height: auto;
    transform: translateY(8px);
    animation: float 5s ease-in-out infinite;
}

@keyframes float {
    8% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(0px);
    }
}

.navbar h1 {
    font-size: 2.1rem;
    font-weight: bold;
    color: #fff;
    font-family: "Dancing Script", cursive;
    margin: 0;
}

.navbar h1:hover {
    color: #a70342;
}

.navbar p {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: -0.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    font-family: "Dancing Script", cursive;
}

.navbar p:hover {
    color: #a70342;
}

/* Botão de Menu (Hamburguer) */
.menuButton {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
    display: none;
    /* Será exibido em telas menores */
    position: absolute;
    bottom: -0.1rem;
    /* right: -10rem; */
    right: -0.1rem;
    text-align: right;
}

/* Lista de Links */
.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease;
}

.list li {
    margin: 0 0.5em;
    text-align: center;
    text-transform: capitalize;
}

.item a,
.item2 a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.item a:hover,
.item2 a:hover {
    color: #a70342;
}

.item2 a {
    font-family: "Montserrat", sans-serif;
    margin-left: 15px;
}

.item2 a:hover {
    color: #bd5704;
}

/* Ícone */
.list svg {
    font-size: 1.5em;
    cursor: pointer;
    margin-top: -2px;
    margin-left: 2px;
    color: #b9006c;
}

.list svg:hover {
    color: #000;
}

.navHeader h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin: 0;
}

/* Exibir o botão de menu em telas menores */
/* Exibir o botão de menu em telas menores */
@media (max-width: 768px) {
    .menuButton {
        display: block;
        /* Exibir o botão de menu */
    }

    .list {
        flex-direction: column;
        /* Mudar a direção da lista para coluna */
        position: absolute;
        /* Posicionar a lista de forma absoluta */
        top: 60px;
        /* Ajustar a posição do menu */
        right: 0;
        background: #e63888;
        /* Cor de fundo do menu */
        width: 100%;
        /* Largura total */
        display: none;
        /* Esconder o menu por padrão */
        padding: 0;
        margin: 0;
    }

    .listOpen {
        display: flex;
        /* Mostrar o menu quando aberto */
    }

    /* Esconder o item 'Contato' em telas grandes */
    .mobileOnly {
        display: block;
        /* Exibir o item somente no mobile */
    }

    .list li {
        margin: 1em 0;
        /* Margem entre os itens do menu */
    }
}

/* Para dispositivos maiores que 768px (desktop/tablet), esconder o item 'Contato' */
@media (min-width: 769px) {
    .mobileOnly {
        display: none;
        /* Esconder o item em telas maiores */
    }
}