.contato {
  display: flex;
  background: #e63888; /* Cor de fundo */
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: auto; /* Ajusta-se ao conteúdo */
}

.info {
  display: flex;
  flex-direction: row; /* Default para telas maiores */
  width: 100%;
  max-width: 1280px;
  justify-content: flex-end;
  margin: 0 auto;
  gap: 0.5rem;
  color: #fff;
  padding-inline-start: 1rem;
}

.email, .link-telefone {
  color: #fff;
  text-decoration: none;
}

.link-telefone:hover {
  text-decoration: underline;
  color: #fff;
}

/* Botão customizado */
.button-container {
  display: flex;
  justify-content: flex-start; /* Posiciona o botão à esquerda */
  margin-left: auto; /* Empurra o botão para a esquerda */
  font-size: 10px;
}

button {
  background: #fff;
  color: #000;
  border: 1px solid #e63888;
  border-radius: 5px;
  padding: 0.5rem 1rem; /* Ajusta o padding (esquerda-direita: 1rem, topo-baixo: 0.5rem) */
  font-size: 0.85rem; /* Diminui o tamanho da fonte */
  height: auto; /* Ajusta a altura ao conteúdo */
}

button:hover {
  background: #e20055;
  color: #fff;
}

.button-container svg {
  font-size: 1.5em;
  cursor: pointer;
  margin-top: -2px;
  margin-left: 2px;
  color: #b9006c;
}

.button-container svg:hover {
  color: #000;
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
  .info {
    flex-direction: column; /* Coluna para telas menores */
    align-items: center; /* Centraliza os itens */
    gap: 1rem; /* Aumenta o espaço entre itens */
    padding-inline-start: 0; /* Remove padding lateral */
  }

  .contato {
    padding: 0.5rem;
  }

  .button-container {
    margin-left: 0;
    justify-content: center; /* Centraliza o botão */
  }
}

@media (max-width: 480px) {
  .info {
    gap: 0.5rem; /* Ajusta espaço entre itens */
    padding: 0;
  }

  .email, .link-telefone {
    font-size: 0.8rem; /* Reduz tamanho da fonte */
  }

  button {
    font-size: 0.8rem; /* Ajusta tamanho do botão */
  }
}

/* Ocultar contato no mobile */
@media (max-width: 768px) {
  .contato {
    display: none; /* Oculta o componente de contato em telas menores */
  }
}