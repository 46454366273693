/* Seu CSS original permanece o mesmo */
.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 400px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto; /* Centraliza o formulário horizontalmente */
  margin-top: 8rem;
}

.registerLink {
  color: #e63888; /* Cor rosa */
  text-decoration: none;
}

.registerLink:hover {
  text-decoration: underline;
}

.form-container {
  min-height: 100vh; /* Ocupa no mínimo toda a altura da janela */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  background-color: #e63888; /* Cor de fundo */
  padding: 2rem; /* Espaçamento para evitar que o formulário encoste nas bordas da página */
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-container {
  position: relative;
}

.passwordContainer {
  position: relative;
  width: 100%;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  font-size: 1.2rem;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px;
}

.input {
  width: 100%;
  max-width: 800px;
  padding: 14px 87px;
  text-align: center;
  border: 1px solid #e63888;
  border-radius: 5px;
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.button:hover {
  background-color: #c10045;
}

.agreementtext {
  font-size: 0.975rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}

/* Adicionando responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .form {
      padding: 1rem;
      max-width: 90%; /* Ajusta a largura em dispositivos menores */
      margin-top: 5rem;
  }

  .formtitle {
      font-size: 1.5rem; /* Aumenta a legibilidade do título */
  }

  .input {
      padding: 10px; /* Reduz o padding para dispositivos menores */
  }

  .button {
      width: 100%; /* Botão ocupa a largura total no mobile */
      padding: 12px;
      font-size: 1rem;
      margin-top: 10px; /* Espaçamento adicional para separação */
  }

  .agreementtext {
      font-size: 0.875rem; /* Texto menor para dispositivos móveis */
      text-align: center;
  }
}

@media (max-width: 480px) {
  .form {
      padding: 1rem;
      margin-top: 3rem;
  }

  .formtitle {
      font-size: 1.25rem;
  }

  .input {
      padding: 8px; /* Mais compacta em telas muito pequenas */
      font-size: 0.9rem;
  }

  .button {
      font-size: 0.9rem;
      padding: 10px;
  }

  .agreementtext {
      font-size: 0.8rem;
  }
}
