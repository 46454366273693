.body {
  justify-content: space-between;
  height: auto;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 0%;
  text-align: center;
  color: #fff;
  user-select: none;
}

@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Dashboard.module.css */

.dashboardContainer {
  background: linear-gradient(135deg, #e63888 0%, #fddce9 100%);
  animation: backgroundAnimation 15s ease infinite;
  background-size: 400% 400%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  padding: 2rem;
}

.heading {
  font-weight: 700;
  font-size: 48px;
  line-height: 3rem;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  margin-top: 8rem;
}

.highlight {
  color: #1f2029;
}

.securityImage {
  width: 100px;
  margin-bottom: -8rem;
  margin-top: -1rem;
}

.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  margin-top: 3rem;
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px;
}

.input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #e63888;
  border-radius: 5px;
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.button:hover {
  background-color: #c10045;
}

.agreementtext {
  font-size: 0.875rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}

.certificates-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.certificate {
  width: 100px;
}

.image {
  width: 100%;
  height: auto;
}

.parcelbutton {
  background-color: #e20055;
  color: white;
  margin-left: 8px;
  font-weight: 600;
  padding: 1px 10px;
  text-decoration: none;
  display: inline-block;
}

/* Adicionando regras de mídia para tornar o layout responsivo */
@media only screen and (max-width: 768px) {
  .dashboardContainer {
    padding: 1rem;
  }

  .heading {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 5rem;
  }

  .form {
    max-width: 90%;
    padding: 1rem;
    margin-top: 2rem;
  }

  .formtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .input {
    padding: 8px;
    font-size: 1rem;
  }

  .button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    margin-top: 10px;
  }

  .certificates-container {
    flex-direction: column;
    gap: 1rem;
  }

  .certificate {
    width: 80px;
  }
}

@media only screen and (max-width: 480px) {
  .heading {
    font-size: 2.0rem;
    line-height: 2rem;
    margin-top: 1rem;
  }

  .form {
    padding: 1rem;
    margin-top: 2rem;
  }

  .input {
    padding: 8px;
    font-size: 0.9rem;
  }

  .button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .agreementtext {
    font-size: 0.8rem;
  }

  .certificates-container {
    gap: 0.5rem;
  }

  .parcelbutton {
    font-size: 0.9rem;
    padding: 5px 15px;
  }

  .securityImage {
    width: 80px;
  }
}
