.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }



  .closeButton {
    top: 10px;
    left: 50px; /* Alinhado à esquerda */
    color: #fff;
    font-size: 10px;
    border-radius: 10px;
    border-color: transparent;
    background-color: #e63888;
    /* font-weight: bold; */
    cursor: pointer;
  }

  .modalContent2 { 
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 830px;
    width: 90%;
    max-height: 100vh; /* Limita a altura máxima do modal */
    overflow-y: auto; /* Permite rolagem se o conteúdo for muito grande */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.cardInputsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Duas colunas */
  gap: 10px; /* Espaçamento entre os inputs */
  justify-items: stretch; /* Esticar os itens para ocupar a coluna */
  margin-top: 20px;
  margin-left: -25px; /* Remover a margem negativa */
}

.cardInputsContainer label {
  display: flex;
  flex-direction: column; /* Colocar o label e input um embaixo do outro */
  align-items: flex-start; /* Alinhar os itens à esquerda */
  margin-bottom: 10px; /* Espaçamento abaixo de cada campo */
}


.cardInputsContainer .full-width {
    grid-column: span 2; /* Este campo vai ocupar a largura de duas colunas */
}

.fullWidthContainer {
  grid-column: span 2; /* Faz o container ocupar as duas colunas da grid */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o label e o input */
  margin-top: 10px; /* Espaço acima do último input */
}

.fullWidthInput {
  display: flex;
  justify-content: center;
  margin-right: 35rem;
  text-align: center;
  width: 400%;
  max-width: 800px;
  padding: 10px;
  border: 1px solid #e63888;
  border-radius: 5px;
}
  
  .closeButton:hover,
  .closeButton:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .modalContent h2 {
    text-align: center;
  }
  .modalContent span {
    text-align: center;
    color: #000;
  }
  .modalContent p {
    text-align: center;
  }

  .redirectButtonContainer {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    margin-top: 20px;
  }
  
  .redirectButton {
    background-color: #e63888; /* Altere conforme necessário */
    color: white;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .redirectButton:hover {
    background-color: #b42b6b; /* Altere conforme necessário */
  }

  .input {
   display: flex;
   justify-content: center;
   margin-left: 4rem;
   text-align: center;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #e63888;
    border-radius: 5px;
}


.input3 {
  display: flex;
  justify-content: center;
  margin-left: 15rem;
  margin-bottom: 1rem;
  text-align: center;
   width: 100%;
   max-width: 300px;
   padding: 10px;
   border: 1px solid #e63888;
   border-radius: 5px;
}
  
  .button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 7rem;
    background-color: #e20055;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* margin-right: 10px; */
    font-size: 1rem;
  }
  
  .button:hover {
    background-color: #c10045;
  }