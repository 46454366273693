.body {
  justify-content: space-between;
  height: auto;
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
  text-align: center;
  color: #fff;
  user-select: none;
}

.body a {
  color: #fff;
  text-decoration: none;
}

.list {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.list li {
  margin: 0 0.5em;
}

.list svg {
  font-size: 1.5em;
  cursor: pointer;
}

.list svg:hover {
  color: #0482bd;
}

.list2 {
  display: flex;
  background-color: #000;
  justify-content: center;
  list-style-type: none;
}

.list2 li {
  margin: 0 0.5em;
}

.list2 svg {
  font-size: 2.5em;
  cursor: pointer;
}

.list2 svg:hover {
  color: #0482bd;
}

.logo {
  margin-top: 50px;
}

.logo img {
  margin-bottom: 100px;
}

.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  margin-top: 3rem;
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px;
}

.input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #e63888;
  border-radius: 5px;
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.button:hover {
  background-color: #c10045;
}

.agreementtext {
  font-size: 0.875rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}

.certificates-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.certificate {
  width: 100px;
}

.image {
  width: 100%;
  height: auto;
}

.h1principal {
  font-weight: 700px;
  font-size: 48px;
  line-height: 3rem; 
  color: #1f2029; 
  font-family: "Roboto";
  font-style: 'normal';
}
.h1principal span {
  color: #e63888;
}
.h1descricao {
  color: #000826;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.h1img {
  width: 100px;
  margin-bottom: -8rem;
  margin-top: -1rem;
}

.personalizado {
  font-size: 35px;
  color: #000;
  margin-top: 35px;
}

.parcelbutton {
  background-color: #e20055;
  color: white;
  margin-left: 8px;
  font-weight: 600;
  padding: 1px 10px;
  text-decoration: none;
  display: inline-block;
}

.parcelbutton2 {
  background-color: #e20055;
  color: white;
  margin-left: 8px;
  font-weight: 600;
  padding: 1px 8px;
  text-decoration: none;
  display: inline-block;
}

/* Adicionando regras de mídia para tornar o layout responsivo */
@media only screen and (max-width: 768px) {
  /* Ajuste no corpo para centralizar e garantir que o conteúdo não se perca */
  .body {
    text-align: center; /* Centraliza o texto horizontalmente */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Alinha os itens horizontalmente */
    min-height: 100vh; /* Garante que a altura mínima da tela seja 100% */
  }

  /* Estilo da imagem */
  .h1img {
    width: 100px;
    margin-bottom: -1rem;
    margin-top: -0rem;
  }

  .personalizado {
    font-size: 35px;
    color: #000;
    margin-top: 35px;
  }

  /* Estilo do h1principal */
  .h1principal {
    font-weight: 700;
    font-size: 48px;
    line-height: 3rem;
    color: #1f2029;
    font-family: "Roboto";
    font-style: 'normal';
    text-align: center; /* Garante que o texto dentro do h1 seja centralizado */
    display: block; /* Garantir que o texto seja exibido corretamente */
  }

  /* Estilo do span dentro do h1principal */
  .h1principal span {
    color: #e63888;
  }

  /* Estilo da h1descricao */
  .h1descricao {
    color: #000826;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center; /* Centraliza o texto da descrição */
    display: block; /* Garantir que o texto seja exibido corretamente */
  }

  /* Ajuste do tamanho do h1 padrão */
  h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center; /* Centraliza o texto */
  }

  .parcelbutton {
    font-size: 1.5rem;
    padding: 5px 10px;
    text-align: center;
    margin-left: -0.3rem;
    position: relative;  /* Tornar o botão posicionado de forma relativa */
    top: -6px;  /* Ajuste o valor conforme necessário para mover o botão para cima */
  }
  

  /* Outros ajustes de responsividade */
  .list li,
  .list2 li {
    margin: 0 0.3em;
  }

  .list svg {
    font-size: 1.2em;
  }

  .list2 svg {
    font-size: 2em;
  }

  .form {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
  }

  .formtitle {
    font-size: 1rem;
  }

  .parcelbutton {
    font-size: 0.875rem;
    padding: 5px 8px;
  }

  .parcelbutton2 {
    font-size: 1.2rem;
    padding: 5px 10px;
    display: relative;
    margin-left: -0.3rem;
    top: -6px;  /* Ajuste o valor conforme necessário para mover o botão para cima */
  }

  /* Logo ajustado */
  .logo img {
    max-width: 80%;
    margin-bottom: 50px;
  }

  .row {
    flex-direction: column;
  }

  .col-md-6 {
    width: 100%;
  }

  .logo img {
    width: 80%;
  }

  .m-4, .m-2 {
    margin: 0;
  }

  .m-4 {
    margin: 0 1rem;
  }

  .spacer-10 {
    height: 1rem;
  }
}


/* Para telas grandes */
.textLarge {
  font-weight: 700;
  font-size: 48px;
  line-height: 3rem;
  color: #1f2029;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

.textHighlight {
  color: #e63888;
}

.textMedium {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #000826;
}

.textSmall {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #000826;
}

.parcelButton {
  color: #e63888;
  text-decoration: none;
}

/* Para telas menores */
@media (max-width: 768px) {
  @media (max-width: 768px) {
    .textLarge, .textMedium, .textSmall {
      word-break: break-word; /* Garante a quebra de palavras longas em telas menores */
      overflow-wrap: break-word;
      max-width: 100%;  /* Garante que o texto se ajuste ao contêiner */
    }
  }

  .imageSmall {
    width: 80px;
    margin-bottom: -4rem;
    margin-top: -1rem;
  }
}
