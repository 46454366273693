.container {
    width: 100%; /* Garante que o container ocupe toda a largura da tela */
    max-width: 1100px; /* Limita o tamanho máximo do container para 1100px */
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 0 20px; /* Adiciona um pequeno padding para dar espaçamento nas laterais */
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .container {
        width: 100%; /* No mobile, o container vai ocupar 100% da largura */
        padding: 0; /* Remove o padding lateral */
        margin: 0; /* Remove a margem lateral */
    }
}

.min-height {
    min-height: 100%;
}
