/* Estilo base para o footer */
.footer {
  background-color: #e20055;
  color: #fff;
  padding: 2rem 1rem;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo img {
  width: 90px;
  height: 37px;
  margin-bottom: 1.5rem;
}

.grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.column {
  flex: 1;
  padding: 0 1rem;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buttonLink {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.buttonLink:hover {
  color: #ddd;
}

.socialIcons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.socialIcons img {
  width: 20px;
  height: 20px;
}

.socialIcons a {
  color: #fff; /* Cor branca para o ícone */
  font-size: 2rem; /* Tamanho maior do ícone */
  transition: color 0.3s;
}

.socialIcons a:hover {
  color: #ddd; /* Cor do ícone ao passar o mouse */
}

.appStores {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.appStores img {
  width: 96px;
  height: 28px;
}

.appStores a {
  color: #fff; /* Cor branca para o ícone */
  font-size: 2rem; /* Tamanho maior do ícone */
  transition: color 0.3s;
}

.appStores a:hover {
  color: #ddd; /* Cor do ícone ao passar o mouse */
}

.address {
  font-size: 0.8rem;
  margin-top: 1rem;
}

/* Estilo do título do footer */
.footer h1 {
  font-size: 2.5rem; /* Tamanho da fonte */
  font-weight: bold; /* Peso da fonte */
  display: flex;
  justify-content: center;
  color: #fff; /* Cor do texto */
  font-family: "Dancing Script", cursive;
  margin-bottom: 3rem;
}

/* Estilo do texto adicional no footer */
.footer23 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-family: 'Roboto';
  margin-top: -3rem;
  margin-bottom: 3rem;
}

/* Adicionando responsividade para dispositivos móveis */
@media only screen and (max-width: 768px) {
  .container {
    padding: 0 1rem; /* Adiciona espaçamento nas laterais */
  }

  .grid {
    display: flex;
    flex-direction: column; /* Empilha as colunas em dispositivos móveis */
    justify-content: center;
    align-items: center;
  }

  .column {
    width: 100%; /* As colunas ocupam 100% da largura em telas pequenas */
    padding: 1rem 0; /* Adiciona espaçamento vertical */
  }

  .title {
    font-size: 1.1rem; /* Diminui o tamanho da fonte */
    margin-bottom: 0.5rem;
  }

  .buttonLink {
    font-size: 0.9rem; /* Diminui o tamanho dos links */
  }

  .socialIcons {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .socialIcons img {
    width: 18px; /* Reduz o tamanho dos ícones */
    height: 18px;
  }

  .appStores {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .appStores img {
    width: 80px; /* Reduz o tamanho dos ícones */
    height: 24px;
  }

  .address {
    font-size: 0.7rem; /* Diminui o tamanho da fonte do endereço */
  }

  /* Ajuste do título no footer */
  .footer h1 {
    font-size: 1.8rem; /* Reduz o tamanho do título */
    margin-bottom: 1.5rem; /* Ajusta o espaçamento */
  }

  .footer23 {
    margin-top: 1rem; /* Ajusta a margem para o texto adicional */
    margin-bottom: 1rem;
  }
}
