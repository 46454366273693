.form {
    background-color: #fff;
    display: block;
    padding: 1rem;
    max-width: 350px;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 0 auto; /* Centraliza o formulário horizontalmente */
    margin-top: 3rem;
  }
  
  .formtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: -10px;
  }
  
  .formgroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .inputgroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .input-container {
    position: relative;
  }
  
  .input-container input,
  .form button {
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px;
  }
  
  .input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #e63888;
    border-radius: 5px;
    /* background-color: #e63888; /* Cor de fundo personalizada */
    /* color: #fff; Cor do texto (opcional, para contraste) */ 
  }
  
  .button {
    background-color: #e20055;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 1rem;
  }
  
  .button:hover {
    background-color: #c10045;
  }
  
  .agreementtext {
    font-size: 0.875rem;
    color: #666;
  }
  
  .link2 {
    color: #007bff;
    text-decoration: none;
  }
  
  .link2:hover {
    text-decoration: underline;
  }