.mr-auto {
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1rem;
}

.sm\:grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.lg\:grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.xl\:max-w-\[90\%\] {
  max-width: 90%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.bg-white {
  background-color: #ffffff;
}

.xl\:w-\[8rem\] {
  width: 8rem;
}

.xl\:h-\[9rem\] {
  height: 9rem;
}

.relative {
  position: relative;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p-4 {
  padding: 1rem;
}

.info-box {
  word-break: break-word;
}

.text-xs {
  font-size: 0.75rem;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.p-1 {
  padding: 0.25rem;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-500 {
  color: #6b7280;
}

.font-bold {
  font-weight: 700;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .services-container {
    margin-left: 10px;
  }
  .sm\:grid-cols-4 {
      grid-template-columns: repeat(2, 1fr); /* Em telas menores que 768px, exibe 2 colunas */
      justify-items: start; /* Alinha os itens para a esquerda dentro da grid */
  }

  .lg\:grid-cols-4 {
      grid-template-columns: repeat(2, 1fr); /* Ajuste para garantir que o layout também fique 2 colunas em telas médias */
      justify-items: start; /* Alinha os itens para a esquerda dentro da grid */
  }
}

