.form {
  background-color: #fff;
  display: block;
  padding: 1.5rem;
  max-width: 400px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto; /* Centraliza o formulário horizontalmente */
}

.registerLink {
  color: #e63888; /* Cor rosa */
  text-decoration: none;
}

.registerLink:hover {
  text-decoration: underline;
}

.form-container {
  min-height: 100vh; /* Ocupa no mínimo toda a altura da janela */
  display: flex;
  margin-top: -3rem;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  /* background-color: #e63888; */
  padding: 1.5rem; /* Espaçamento para evitar que o formulário encoste nas bordas da página */
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
}

.input-container {
  flex: 1 1 48%; /* Garante que dois inputs caibam na mesma linha */
  max-width: 48%; /* Limita a largura de cada input a 48% */
  position: relative;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e63888;
  margin: 8px 0; /* Ajuste para margem apenas no eixo vertical */
}

.input {
  width: 100%; /* Garante que o input ocupe toda a largura disponível */
  padding: 14px 0px;
  text-align: center;
  border: 1px solid #e63888;
  border-radius: 5px;
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem; /* Adiciona margem no topo para separar o botão dos campos */
}

.button:hover {
  background-color: #c10045;
}

.agreementtext {
  font-size: 0.975rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}
