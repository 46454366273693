.accordion-header-custom {
  background-color: #e20055;
  color: #fff;
  font-weight: bold; /* Adiciona negrito ao texto */
}

.accordion-body-custom {
  background-color: #fff;
  color: #000;
  font-weight: normal; /* Mantém o texto no corpo sem negrito, se desejar */
}
  
  .accordion-item-custom {
    border: none;
  }
  