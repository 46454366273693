.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  margin-top: 0rem;
}

/* Sidebar.module.css */
.hideOnMobile {
  display: none;
}

.registerLink {
  color: #e63888;
  text-decoration: none;
}

.registerLink:hover {
  text-decoration: underline;
}

.form-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e63888;
  padding: 2rem;
}

.formtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: -10px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px;
}

.input {
  width: 100%;
  max-width: 300px;
  padding: 14px 12px;
  border: 1px solid #e63888;
  border-radius: 5px;
}

.button {
  background-color: #e20055;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.button:hover {
  background-color: #c10045;
  color: #fff;
}

.agreementtext {
  font-size: 0.975rem;
  color: #666;
}

.link2 {
  color: #007bff;
  text-decoration: none;
}

.link2:hover {
  text-decoration: underline;
}

/* Conteúdo do Dashboard */
.dashboardContent {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

/* Estilização da Sidebar */
.sidebar {
  display: flex;
  background: linear-gradient(135deg, rgba(226, 0, 85, 0.8) 0%, rgba(226, 0, 85, 0.6) 100%);
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar h1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
  font-family: "Dancing Script", cursive;
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

.sidebar h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 18px;
  height: 3px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, #e63888 50%, rgb(255, 255, 255) 100%);
  background-size: 200% 100%;
  background-position: 0 0;
  animation: lineAnimation 2s infinite;
}

@keyframes lineAnimation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: -3rem;
}

button {
  width: 100%;
  text-align: center;
}

.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 1rem;
}

.submenu li {
  margin: 0.5rem 0;
}

.submenu button {
  background: rgb(240, 240, 240);
  color: #000;
  border: 1px solid #e63888;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  height: auto;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.submenu button.active {
  background-color: #e63888;
  color: #fff;
  border: 1px solid #e63888;
}

.submenu button.active:hover {
  background-color: #d12c77;
  color: #fff;
}

.mainContent {
  flex-grow: 1;
  margin-left: 20px;
}

.cardContainer {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
}

.card h2 {
  font-size: 1.5rem;
  color: #e20055;
}

.card p {
  font-size: 1rem;
  color: #555;
}

/* Regras de mídia para responsividade */
@media (max-width: 768px) {
  /* Mostrar o botão hamburger apenas em dispositivos móveis */
  .hamburgerMenuButton {
    display: block; /* Exibe o botão hamburger em telas pequenas */
    font-size: 1rem; /* Ajuste do tamanho do ícone */
    background: none;
    border: none;
    cursor: pointer;
    color: #fff; /* Cor do ícone */
    border-radius: inherit;
    position: relative;
  }

  .hideOnMobile {
    display: none; /* Oculta em telas menores */
  }

  /* Outros estilos para dispositivos móveis */
  .dashboardContent {
    flex-direction: column;
    padding: 1rem;
  }

  /* Sidebar em dispositivos móveis */
  .sidebar {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    position: relative;
    top: -2rem;
    box-shadow: none; /* Remova ou suavize a sombra */
  }

  /* Estilos para o título da sidebar em dispositivos móveis */
  .sidebar h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  /* Ajuste os botões na sidebar */
  .submenu button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    width: 100%; /* Deixa os botões com largura total em dispositivos móveis */
  }

  /* Ajuste o layout das cartas (cards) */
  .cardContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .card {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Ajuste de botões para dispositivos móveis */
  .buttonGroup {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    margin-left: 0;
  }

  .buttonGroup button {
    width: 100%; /* Botões com largura total em dispositivos móveis */
    font-size: 0.9rem;
  }
}

/* Ocultar o botão hamburger em telas grandes */
@media (min-width: 769px) {
  .hamburgerMenuButton {
    display: none; /* Oculta o botão em telas maiores */
  }

  .hideOnMobile {
    display: block; /* Garante que fique visível no desktop */
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
  margin-left: 2rem;
}


button {
  flex: 0 1 calc(30% - 10px);
  padding: 10px 20px;
  /* background-color: #e63888; */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-sizing: border-box;
}

button:hover {
  background-color: #d12c77;
  color: #fff;
}
